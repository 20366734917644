/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer2 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "profhilo1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      second: file(relativePath: { eq: "profhilo2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      third: file(relativePath: { eq: "profhilo3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.profhilo}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Molekuła Młodości H+L
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="profhilo ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>
              Czym są biostymulatory tkankowe na bazie kwasu hialuronowego?
            </Subtitle>
            <Paragraph>
              Biostymulatory tkankowe na bazie kwasu hialuronowego to
              specjalistyczne preparaty wykorzystywane w{' '}
              <b>medycynie estetycznej</b>, które mają na celu poprawę kondycji
              skóry poprzez stymulację naturalnych procesów regeneracyjnych
              organizmu. Kwas hialuronowy jest naturalnie występującym w
              organizmie człowieka polisacharydem, który odgrywa kluczową rolę w
              utrzymaniu nawilżenia, elastyczności i objętości skóry. Z wiekiem
              poziom kwasu hialuronowego w skórze maleje, co przyczynia się do
              powstawania zmarszczek, utraty jędrności i innych oznak starzenia.
            </Paragraph>
            <Paragraph>
              <b>Biostymulatory na bazie kwasu hialuronowego</b> są
              zaprojektowane tak, aby naśladować działanie naturalnego kwasu
              hialuronowego, ale również iść o krok dalej, stymulując skórę do
              regeneracji i produkcji nowych włókien kolagenowych i
              elastynowych. Dzięki temu nie tylko poprawiają nawilżenie i
              objętość skóry, ale także przyczyniają się do jej długotrwałej
              regeneracji i odmłodzenia. Są to preparaty iniekcyjne, które
              dostarczane są bezpośrednio do skóry w miejscach wymagających
              rewitalizacji.
            </Paragraph>
            <Paragraph>
              Biostymulatory na bazie kwasu hialuronowego mogą różnić się
              składem, stężeniem, a także wielkością cząsteczek kwasu
              hialuronowego, co pozwala na dostosowanie terapii do
              indywidualnych potrzeb pacjenta i specyfiki problemu
              skórnego.Stosowanie biostymulatorów na bazie kwasu hialuronowego
              jest procedurą minimalnie inwazyjną, a efekty zabiegu są zwykle
              widoczne już po pierwszych sesjach. Pełen efekt regeneracyjny
              rozwija się stopniowo, wraz z naturalnym procesem odnowy skóry, co
              może trwać kilka tygodni.
            </Paragraph>
            <Subtitle>Wskazania do zabiegu Molekuła Młodości H+L</Subtitle>
            <Paragraph>
              Wraz z wiekiem zmniejsza się ilość oraz jakość kwasu hialuronowego
              występującego w skórze co prowadzi do jej zwiotczenia. Starzenie
              twarzy najczęściej obserwowane jest w jej dolnej i środkowej
              części. Policzki oraz linia żuchwy tracą wyraźny kontur a profil
              się rozmywa. Nadmierna wiotkość skóry sprzyja powstawaniu
              „chomiczków” tzw. efektów grawitacyjnych. Dzieje się tak, z powodu
              atrofii skóry. Skórze brakuje sprężystości. Molekuła Młodości H+L
              zawiera najwyższe jak do tej pory stężenie kwasu hialuronowego
              (dawka 64 mg / strzykawkę) w formie 2 rodzajów łańcuchów kwasu
              hialuronowego połączonych termicznie (bez dodatków chemicznych).
              Nie jest wypełniaczem – po podaniu migruje pod skórą unosząc ją -
              daje „efekt miliona sprężynek”, efekt wygładzenia skóry. Przywraca
              utracony kontur, wygładza zmarszczki, liftinguje, natychmiast
              nadaje skórze utracony efekt sprężystości. Obecnie zabiegi z
              użyciem stymulatorów tkankowych to najmodniejsze zabiegi medycyny
              estetycznej dające doskonałe rezultaty u młodszej grupy pacjentek.
              Daje efekt liftingu, poprawy owalu twarzy oraz likwidacji
              zmarszczek.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu Molekuła Młodości H+L </Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. Polega on na podaniu preparatu Molekuła
              Młodości H+L , za pomocą cienkiej igły w precyzyjnie wyznaczone
              punkty na twarzy.
            </Paragraph>
            <Paragraph>
              Zabieg wykonuje się w miejscu wcześniej znieczulonym przy pomocy
              specjalnego kremu.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty?</Subtitle>
            <Paragraph>
              Efekty zabiegu widoczne są już po miesiącu. W celu kompleksowego
              odmłodzenia skóry najczęściej wystarczają 2 zabiegi, w odstępie 30
              dni.
            </Paragraph>
            <Paragraph>
              W niektórych przypadkach przy bardzo złym stanie skóry zalecane
              jest wykonanie dodatkowo jednego zabiegu po dwóch miesiącach od
              ostatniego zabiegu.
            </Paragraph>
            <Paragraph>
              Efekt utrzymuje się 1-1,5 roku. Zdjęcia ukazują efekt zabiegu u
              konkretnego pacjenta. Efekt zabiegu może się różnić w zależności
              od indywidualnych cech pacjenta, liczby powtórzeń zabiegu,
              stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności
              i doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <SImageWrapper>
              <Image
                src={images.first.childImageSharp}
                alt="biostymulator tkankowy profhilo molekuła młdości m+h"
              />
              <Image
                src={images.second.childImageSharp}
                alt="biostymulator tkankowy na twarz profhilo molekuła młdości m+h"
              />
              <Image
                src={images.third.childImageSharp}
                alt="biostymulator tkankowy zabieg na twarz profhilo molekuła młdości m+h"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena zabiegu Molekuła Młodości H+L</Subtitle>
            <Paragraph>
              Cena pojedyczego zabiegu to 1 200 zł. Zapraszamy do rezerwacji.
              Przy pierwszej wizycie odbierz swoją kartę lojalnościową i
              otrzymaj -30% na czwarty zabieg medycyny estetycznej wykonany w
              Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>
              Przeciwwskazania do zabiegu Molekuła Młodości H+L
            </Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer2;

export const Head = () => <SEO tags={TAGS.profhilo} />;
